<template>
  <div class="DataBase">
    <h1 class="title">DataBase</h1>
    <DataBaseTable></DataBaseTable>
  </div>
</template>

<script>
import DataBaseTable from "../components/databasetable.vue";

export default {
  name: "contact",
  data() {
    return {};
  },
  methods: {},
  components: {
    DataBaseTable: DataBaseTable,
  },
};
</script>

<style scoped>
.title-font {
  font-size: 28pt;
  font-weight: 700;
}
.text-font {
  font-size: 14pt;
  font-weight: 500;
}
.DataBase {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  min-height: 66vh;
}
.column-flex {
  display: flex;
  flex-direction: column;
}
.row-flex {
  display: flex;
  flex-direction: row;
}
.title {
  font-size: 28pt;
  margin: 20px 0;
  padding: 0 10px;
  text-align: center;
  color: white;
}
</style>
